import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GlobalStyle from '../../styles/global'
import {BaseCSS} from 'styled-bootstrap-grid';
import {GridThemeProvider} from 'styled-bootstrap-grid';
import {ThemeProvider} from 'styled-components';
import Header from "./Header";
import Footer from "./Footer";
import breakpoints from "../../styles/breakpoints";
import ContentContainer from "../Core/ContentContainer";
import {Helmet} from "react-helmet"

const PageContainer = styled.div`
  width: 100%;
  margin-top: ${props => props.login ? "0" : "32px"};
  flex-grow: 1;
    
  @media(min-width: ${breakpoints.md}) {
    margin-top: ${props => props.login ? "0" : "50px"};
  }
`;

const GlobalPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  row: {
    padding: 0
  },
  col: {
    padding: 0
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1600,
      lg: 1600,
      md: 1600,
      sm: 1600,
      xs: 1600,
    },
  },
};

const styledTheme = {};

const Layout = ({children, login}) => (
  <React.Fragment>
    <ThemeProvider theme={styledTheme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <GlobalStyle/>
        <BaseCSS/>
        <Helmet>
          <script type="text/javascript">
            {`// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/xqaxvjk1'
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/xqaxvjk1';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            if (typeof window.Intercom !== "undefined") {
                window.Intercom("boot", {
                app_id: "xqaxvjk1"
              });
            }
            `}
          </script>
        </Helmet>
        <GlobalPageContainer>
          {login !== true && <Header/>}
          <PageContainer login={login}>
            <ContentContainer>
              {children}
            </ContentContainer>
          </PageContainer>
          {login !== true && <Footer/>}
        </GlobalPageContainer>
      </GridThemeProvider>
    </ThemeProvider>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;