import React from "react"
import {Link} from "gatsby";
import ContentContainer from "../Core/ContentContainer";
import styled from "styled-components";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const FooterContainer = styled.div`
  background ${colors.black};
  width: 100%;
`;

const FooterContent = styled.div`
  margin: 20px 0;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -12px;

  a {
    color: ${colors.white};
    ${fonts.montserratBold};
    text-decoration: none;
    font-size: 12px;
    margin-left: 12px;
    margin-right: 12px;
    line-height: 24px;   
    
    &:hover,
    &:active,
    &.active,
    &:focus {
      color: ${colors.green};
    }
  }
`;

const Copyright = styled.div`
  display: block;
  color: ${colors.white};
  font-size: 12px;
  line-height: 24px;
  margin: 12px 0;
`;

const Footer = () => (
  <FooterContainer>
    <ContentContainer>
      <FooterContent>
        <Nav>
          <Link to={'/privacy-policy'}>Privacy Policy</Link>
          {/*<Link to={'/documentation'}>Documentation</Link>*/}
        </Nav>
        <Copyright>
          © {new Date().getFullYear()} Theme Deploy. All rights reserved.
        </Copyright>
      </FooterContent>
    </ContentContainer>
  </FooterContainer>
);

export default Footer
