import React from "react"
import styled from "styled-components";
import logo from "../../resources/img/logo/logo.svg";
import ContentContainer from "../Core/ContentContainer";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import {Link} from "gatsby";
import ExternalLinkButton from "../Core/ExternalLinkButton";

const HeaderContainer = styled.div`
  width: 100%;
  background: ${colors.white};
`;

const HeaderContent = styled.div`
  height: 60px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media(min-width: ${breakpoints.md}) {
    height: 100px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  @media(min-width: ${breakpoints.md}) {
    a {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

const LogoContainer = styled.div`  
`;

const LogoLink = styled(Link)`
  height: 36px;
  position: relative;
  display: block;

  @media(min-width: ${breakpoints.md}) {
    height: 50px;
  }
`;

const LogoImage = styled.img`
  height: 100%;
  display: block;
`;

// const HideOnMobile = styled.div`
//   display: none;
//
//   @media(min-width: ${breakpoints.md}) {
//     display: flex;
//   }
// `;

const Header = () => {
  return (
    <HeaderContainer>
      <ContentContainer>
        <HeaderContent>
          <LogoContainer>
            <LogoLink to={'/'}>
              <LogoImage src={logo} alt={"Theme Deploy"} />
            </LogoLink>
          </LogoContainer>
          <Nav>
            {/*<HideOnMobile>*/}
            {/*  <LinkButton to={'/documentation'}*/}
            {/*              small={1}*/}
            {/*              activeClassName={'active'}>*/}
            {/*    Documentation*/}
            {/*  </LinkButton>*/}
            {/*</HideOnMobile>*/}
            <ExternalLinkButton href={process.env.APP_URL} small={true}>Get Started</ExternalLinkButton>
          </Nav>
        </HeaderContent>
      </ContentContainer>
    </HeaderContainer>
  )
};

export default Header;
