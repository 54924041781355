const colors = {
  white: '#FFFFFF',
  black: '#434343',
  blackDark: '#282828',
  green: '#22CEB6',
  red: '#F83E12',
  grey: '#F7F7F7',
  greyLight: '#F8F8F8',
  greyDark: '#D9D9D9'
};

export default colors;